import { useRef, useEffect, useState, useMemo } from "react";
import { tagular } from "@cohesion/tagular";
import { Button } from "@components/button";
import { Loading } from "@components/loading";
import { LogoGlobe } from "@icons/logos";
import { useIntersectionObserver } from "@utils/useIntersectionObserver";
import { useScreens } from "@utils/component";
import data from "@constants/best-in-travel/2025";

const useMediaSelection = (media, orientation) =>
  useMemo(() => {
    const filteredMedia = media.filter(
      item => item.orientation === orientation
    );
    const randomIndex = Math.floor(Math.random() * filteredMedia.length);
    return filteredMedia.length > 0
      ? filteredMedia[randomIndex].src
      : media[0].src;
  }, [media, orientation]);

export function BITPromo() {
  const { media } = data;
  const videoRef = useRef();
  const [firstLoad, setFirstLoad] = useState(true);
  const { setNodeRef, isVisible } = useIntersectionObserver(
    {
      threshold: 0.5,
    },
    false
  );
  const { screen } = useScreens();
  const orientation = screen === "sm" ? "portrait" : "landscape";
  const videoSrc = useMediaSelection(media, orientation);

  // State to ensure consistent rendering between server and client
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (videoRef.current && isVisible && firstLoad) {
      videoRef.current
        .play()
        // eslint-disable-next-line no-console
        .catch(error => console.warn("Video autoplay error", error));
      tagular("view", {
        webElement: {
          location: "SECTION",
          elementType: "BUTTON",
          position: "BIT",
          text: "DISCOVERTHEWINNERS",
        },
      });
      setFirstLoad(false);
    }
  }, [firstLoad, isVisible]);

  return (
    <section className="relative w-full overflow-hidden" ref={setNodeRef}>
      {isClient ? (
        <video
          autoPlay
          loop
          id="expert-video"
          muted
          playsInline
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
          ref={videoRef}
          poster={videoSrc.poster}
        >
          <source src={videoSrc.mp4} type="video/mp4" />
          <track kind="captions" />
        </video>
      ) : (
        <Loading className="absolute w-full top-2" />
      )}

      <div className="absolute top-0 w-full h-full bg-black/30" />
      <div className="absolute bottom-0 w-full h-96 bg-gradient-to-t from-black" />
      <div className="absolute w-full h-full rounded-t-pill  lg:w-[1400px] lg:aspect-square mx-auto lg:h-auto lg:rounded-full overflow-hidden left-1/2 transform -translate-x-1/2 top-7 lg:top-[150px] border border-white" />

      <div className="relative">
        <LogoGlobe className="w-16 h-16 lg:w-[68px] lg:h-[68px] mx-auto mt-20 mb-24 text-white lg:mt-[218px] lg:mb-[132px]" />
        <h2>
          <div className="font-instrument font-semibold leading-[0.85] mb-4 w-full text-center text-[110px] lg:text-[200px] ">
            Best in Travel
          </div>{" "}
          <div className="text-[30px] lg:text-4xl font-semibold leading-none font-neue">
            2025
          </div>
        </h2>

        <div className="flex justify-center mt-12 mb-20 lg:mb-[166px] text-center ">
          <div>
            <Button
              className="btn btn-primary"
              href="/best-in-travel"
              onClick={() => {
                tagular("click", {
                  actionOutcome: "INTERNALLINK",
                  outboundUrl: "/best-in-travel",
                  webElement: {
                    location: "SECTION",
                    elementType: "BUTTON",
                    position: "BIT",
                    text: "DISCOVERTHEWINNERS",
                  },
                });
              }}
            >
              Discover the winners
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
